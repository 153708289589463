import { Link } from "gatsby"
import React, { Component } from "react"
import Layout from "../components/Layout/Layout/Layout"
import SEO from "../components/SEO/SEO"
import Header from "../components/Shop/Header/Header"
import Utils from "../utils/node.utils"
import ReactMarkdown from "react-markdown"

function flatten(text, child) {
  return typeof child === "string"
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text)
}

function HeadingRenderer(props) {
  let children = React.Children.toArray(props.children)
  let slug = Utils.CreateSlug(children.reduce(flatten, ""))

  const className = props.level == 3 ? "text-left" : "text-center"
  console.log(props.level)

  return React.createElement(
    // "h" + props.level,
    "h" + 4,
    { id: slug, className: className },
    props.children
  )
}

function LinkRenderer(props) {
  const site = "https://lottamae.com"
  if (props.href.startsWith(site)) {
    const intLink = props.href.replace(site, "")
    return (
      <u>
        <Link to={intLink} title={intLink}>
          {props.children}
        </Link>
      </u>
    )
  }
  return (
    <u>
      <a href={props.href} title={props.href}>
        {props.children}
      </a>
    </u>
  )
}

function ImageRenderer(props) {
  return (
    <div className="text-center">
      <img
        src={props.src}
        alt={props.alt}
        title={props.alt}
        className="img-fluid"
      >
        {props.children}
      </img>
    </div>
  )
}

function ParagraphRenderer(props) {
  return <div className="mb-3 text-justify">{props.children}</div>
}

class Blog extends Component {
  createContent = () => {
    const { blog } = this.props

    let result = new Array(blog.content.length)

    for (let i = 0; i < blog.content.length; i++) {
      const part = blog.content[i]
      switch (part.key) {
        case "markdown":
          result.push(this.getMarkdown(part.value, i))
          break
      }
    }
    result.push(<div key={result.length} className="mb-6"></div>)
    return result
  }

  getMarkdown(content, i) {
    if (content) {
      return (
        <ReactMarkdown
          key={i}
          children={content}
          components={{
            h1: HeadingRenderer,
            h2: HeadingRenderer,
            h3: HeadingRenderer,
            h4: HeadingRenderer,
            h5: HeadingRenderer,
            h6: HeadingRenderer,
            a: LinkRenderer,
            img: ImageRenderer,
            p: ParagraphRenderer,
          }}
        />
      )
    }
  }

  render() {
    const { title, thumbnail } = this.props
    return (
      <div className="container">
        <div className="blog-h-resize text-uppercase text-center mb-4">
          {title}
        </div>
        <div className="text-center">
          <img
            src={thumbnail}
            alt={title}
            title={title}
            className="img-fluid mb-4"
          />
        </div>
        {this.createContent()}
      </div>
    )
  }
}

export default class Post extends Component {
  constructor(props) {
    super(props)
    const blog = this.props.pageContext.edge
    if (typeof window !== "undefined") {
      Utils.NavigateToBrowserLanguage(
        props.pageContext.language,
        "/blog/" + blog.slug,
        props.location.state,
        props.location.search
      )
    }
  }

  render() {
    const {
      location: { origin },
      pageContext: { language, commonContent },
    } = this.props
    const commonContentMap = Utils.ContentListToMap(commonContent.item.content)
    const blog = this.props.pageContext.edge
    const selfKey = "__" + blog.slug
    const breadCrumbs = ["menu_home", "menu_blog", selfKey]
    const selfLink = Utils.GetLanguageLink(language, "/blog/" + blog.slug)

    const title = Utils.GetContentKeyValue(blog.content, "title")
    const description = Utils.GetContentKeyValue(blog.content, "description")
    const thumbnail = Utils.GetContentKeyValue(blog.content, "thumbnails")

    commonContentMap[selfKey] = {
      key: selfKey,
      value: `[${title}](${selfLink})`,
    }

    return (
      <div>
        <Layout
          origin={origin}
          originPage={"blog/" + blog.slug}
          language={language}
          commonContentMap={commonContentMap}
        >
          <SEO
            title={title}
            description={description}
            language={language}
            keywords={Utils.GetKeywordsFromTags(blog.tags)}
            image={thumbnail}
            selfLink={"blog/" + blog.slug}
          />
          <Header
            breadCrumbs={breadCrumbs}
            commonContentMap={commonContentMap}
          />
          <Blog
            blog={blog}
            title={title}
            description={description}
            thumbnail={thumbnail}
          />
        </Layout>
      </div>
    )
  }
}
